import i18n from 'i18n';

// Transform number (Sunday (0) - Saturday (6)) to day of week as string
export const getDayString = (day: number) => {
  switch (day) {
    case 0:
      return i18n.t('common:COMMON.DAY.SUNDAY');
    case 1:
      return i18n.t('common:COMMON.DAY.MONDAY');
    case 2:
      return i18n.t('common:COMMON.DAY.TUESDAY');
    case 3:
      return i18n.t('common:COMMON.DAY.WEDNESDAY');
    case 4:
      return i18n.t('common:COMMON.DAY.THURSDAY');
    case 5:
      return i18n.t('common:COMMON.DAY.FRIDAY');
    case 6:
      return i18n.t('common:COMMON.DAY.SATURDAY');
    default:
      return day;
  }
};

// Transform number (January (0) - December (11)) to month as string
export const getMonthString = (month: number) => {
  switch (month) {
    case 0:
      return i18n.t('common:COMMON.MONTH.JAN');
    case 1:
      return i18n.t('common:COMMON.MONTH.FEB');
    case 2:
      return i18n.t('common:COMMON.MONTH.MAR');
    case 3:
      return i18n.t('common:COMMON.MONTH.APR');
    case 4:
      return i18n.t('common:COMMON.MONTH.MAY');
    case 5:
      return i18n.t('common:COMMON.MONTH.JUNE');
    case 6:
      return i18n.t('common:COMMON.MONTH.JULY');
    case 7:
      return i18n.t('common:COMMON.MONTH.AUG');
    case 8:
      return i18n.t('common:COMMON.MONTH.SEP');
    case 9:
      return i18n.t('common:COMMON.MONTH.OCT');
    case 10:
      return i18n.t('common:COMMON.MONTH.NOV');
    case 11:
      return i18n.t('common:COMMON.MONTH.DES');
    default:
      return month;
  }
};

// Transform minutes to "x hours, y minutes"
export const formatMinutes = (mins: number) => {
  const num = mins;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return rhours + ' ' + i18n.t('common:COMMON.HOURS') + ', ' + rminutes + ' ' + i18n.t('common:COMMON.MINS');
};

// Transform seconds to "Xh Ym" or "Ym Zs"
export const formatSeconds = (seconds: number) => {
  const hours = seconds / 3600;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.floor(minutes);
  const sec = (minutes - rminutes) * 60;
  const rseconds = Math.round(sec);
  return (
    (rhours > 0 ? rhours + i18n.t('common:COMMON.SHORTHOURS') : '') +
    ((rhours > 0 ? ' ' : '') + rminutes + i18n.t('common:COMMON.SHORTMINUTES')) +
    (rhours < 1 ? ' ' + rseconds + i18n.t('common:COMMON.SHORTSECONDS') : '')
  );
};

// Add leading zero to numbers below 10. Ex: 2 -> 02, 12 -> 12
const addLeadingZero = (i: number) => (i < 10 ? '0' + i : i);

// Transform date to formatted string
export const getFormattedDate = (date: Date, day = true, time = true) => {
  if (!day && !time) {
    return date.getDate() + ' ' + getMonthString(date.getMonth());
  } else if (!day) {
    return addLeadingZero(date.getHours()) + ':' + addLeadingZero(date.getMinutes());
  } else if (!time) {
    return getDayString(date.getDay()) + ' ' + date.getDate() + ' ' + getMonthString(date.getMonth()) + '. ';
  } else {
    return (
      getDayString(date.getDay()) +
      ' ' +
      date.getDate() +
      ' ' +
      getMonthString(date.getMonth()) +
      '. ' +
      addLeadingZero(date.getHours()) +
      ':' +
      addLeadingZero(date.getMinutes())
    );
  }
};

// Transform date-interval to formatted string
export const getFormattedDateInterval = (startDate: Date, endDate: Date) => {
  if (startDate.getDate() !== endDate.getDate()) {
    return getFormattedDate(startDate) + ' - ' + getFormattedDate(endDate);
  } else {
    return getFormattedDate(startDate) + ' - ' + addLeadingZero(endDate.getHours()) + ':' + addLeadingZero(endDate.getMinutes());
  }
};

// Transform date to a string showing time since the appropriate of seconds/minutes/hours/days
export const getTimeSince = (date: Date) => {
  const ms = new Date().getTime() - date.getTime();
  const sec = Number((ms / 1000).toFixed(0));
  const min = Number((ms / (1000 * 60)).toFixed(0));
  const hrs = Number((ms / (1000 * 60 * 60)).toFixed(0));
  const days = Number((ms / (1000 * 60 * 60 * 24)).toFixed(0));
  if (sec < 60) {
    return sec + ' ' + i18n.t('common:COMMON.SINCE.SECONDS');
  } else if (min < 60) {
    return min + ' ' + i18n.t('common:COMMON.SINCE.MINUTES');
  } else if (hrs < 24) {
    return hrs + ' ' + i18n.t('common:COMMON.SINCE.HOURS');
  } else if (days < 7) {
    return days + ' ' + i18n.t('common:COMMON.SINCE.DAYS');
  } else {
    return getFormattedDate(date, true, false);
  }
};

// Function to split array into arrays with a custom max length to not exceed batch write limits
// Arr: the array to split
// Size: size of the new arrays
export const chunkArrayInGroups = (arr: Array<unknown>, size: number) => {
  const myArray = [];
  for (let i = 0; i < arr.length; i += size) {
    myArray.push(arr.slice(i, i + size));
  }
  return myArray;
};

export function chordToString(chord: number | string, minor: boolean) {
  if ((chord as string) === 'pause') {
    return '-';
  }
  chord = chord as number;
  let char = '';
  let charNum = Math.floor(chord / 2) + 1;
  if (chord <= 2) {
    charNum += -1;
  }
  switch (chord) {
    case 1:
      charNum += 1;
      break;
    case 4:
      charNum += -1;
      break;
  }
  char = String.fromCharCode(charNum + (minor ? 97 : 65));
  switch (chord) {
    case 1:
    case 6:
      char += 'b';
      break;
    case 4:
    case 9:
    case 11:
      char += '#';
      break;
  }
  return char;
}
export function noteToMajorScaleNote(note: number, key = 0) {
  const shiftedNote = (note + 12 - key) % 12;
  let numWholeNotes = shiftedNote / 2;
  if (shiftedNote > 4) {
    numWholeNotes += 0.5;
  }
  if (numWholeNotes % 1 !== 0) {
    return -1 * (Math.floor(numWholeNotes) + 2);
  }
  return Math.floor(numWholeNotes) + 1;
}

export const chordToTones = (chord: number, minor: boolean, key = 0) => {
  let notes = minor ? [0, 3, 7] : [0, 4, 7];
  const shiftedChord = (chord + 12 - key) % 12;
  notes = notes.map((note) => (note + shiftedChord + key) % 12);
  const numberOfRotations = shiftedChord <= 2 ? 0 : shiftedChord <= (minor ? 6 : 5) ? 1 : shiftedChord <= 9 ? 2 : 3;
  const voice = [notes[(3 - numberOfRotations) % 3], notes[(4 - numberOfRotations) % 3], notes[(5 - numberOfRotations) % 3]];
  return voice;
};

export function chordToSynthNotes(chord: number, minor: boolean, key = 0) {
  const defaultPitch = 4;
  const notes = chordToTones(chord, minor, key);

  let keyNote = defaultPitch * 12 - 3;
  if (keyNote >= 55) {
    keyNote -= 12;
  }
  let value = keyNote + notes[0] + (notes[0] >= 6 ? -12 : 0);
  const synthNotes: { chordString: string; pitchNotation: number }[] = [];
  for (let i = 0; i < 3; i++) {
    const dist = notes[i] - notes[i - 1 < 0 ? 0 : i - 1];
    value += dist + (dist < 0 ? 12 : 0);

    synthNotes.push({ chordString: chordToString((value + 3) % 12, false), pitchNotation: Math.floor(value / 12) });
  }
  return synthNotes;
}

export const choirVoices = (chord: number, minor: boolean, key = 0) => {
  const voice = chordToTones(chord, minor, key);
  return voice.map((note) => noteToMajorScaleNote(note, key));
};

export const choirBoomwhackers = (chord: number, minor: boolean, key = 0) => {
  const voice = chordToTones(chord, minor, key);
  return voice;
};

export const boomWhackersColors = [
  '#3739C5',
  '#5B1B57',
  '#FD13A8',
  '#FF0739',
  '#DC2F0D',
  '#FE7234',
  '#FE9619',
  '#FEDE45',
  '#B2F158',
  '#219140',
  '#028C84',
  '#0D26B5',
];

export const instruments: Record<string, string> = {
  guitar: i18n.t('common:INSTRUMENT.GUITAR'),
  piano: i18n.t('common:INSTRUMENT.PIANO'),
  ukulele: i18n.t('common:INSTRUMENT.UKULELE'),
  violin: i18n.t('common:INSTRUMENT.VIOLIN'),
  bass: i18n.t('common:INSTRUMENT.BASS'),
};

export const formatYouTubeLink = (link: string, format: 'id' | 'youtube' | 'embed' | 'youtu.be' = 'youtube', startTime = 0) => {
  if (!link) {
    return null;
  }
  const trimmedLink = link.trim();
  let linkFormat = '';
  switch (format) {
    case 'youtube':
      linkFormat = 'https://www.youtube.com/watch?v=';
      break;
    case 'embed':
      linkFormat = 'https://www.youtube.com/embed/';
      break;
    case 'youtu.be':
      linkFormat = 'https://youtu.be/';
      break;
  }
  let id: string | undefined = undefined;

  if (trimmedLink.length === 11) {
    id = trimmedLink;
  } else {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = trimmedLink.match(regExp);
    if (match && match[2].length === 11) {
      id = match[2];
    }
  }

  if (!id) {
    return null;
  }

  if (startTime <= 0 || format === 'id') {
    return linkFormat + id;
  }
  let startTimeString = '';
  switch (format) {
    case 'youtube':
      startTimeString = '&t=' + startTime + 's';
      break;
    case 'embed':
      startTimeString = '?start=' + startTime;
      break;
    case 'youtu.be':
      startTimeString = '?t=' + startTime;
      break;
  }
  return linkFormat + id + startTimeString;
};

export const getStartTimeFromYouTubeLink = (link: string) => {
  if (!formatYouTubeLink(link)) {
    return undefined;
  }
  const linkType = ['https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/', 'https://youtu.be/']
    .map((linkFormat, index) => (link.includes(linkFormat) ? index : undefined))
    .filter((isUndefined) => isUndefined !== undefined);
  if (linkType.length === 0) {
    return undefined;
  }
  const timeFormat = linkType[0] === 0 ? '&t=' : linkType[0] === 1 ? '?start=' : linkType[0] === 2 ? '?t=' : '';
  if (timeFormat === '') {
    return undefined;
  }
  let timeString = link.split(timeFormat)[1] || '';

  if (linkType[0] === 0) {
    timeString = timeString.split('s')[0];
  }
  if (timeString || timeString === '') {
    const startTime = Number(timeString);
    if (startTime || startTime === 0) {
      return startTime;
    }
  }
  return undefined;
};

export const checkValidUrl = (url: string) => {
  // regex found here: https://stackoverflow.com/questions/161738/what-is-the-best-regular-expression-to-check-if-a-string-is-a-valid-url
  const regex =
    // eslint-disable-next-line prettier/prettier, no-useless-escape
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  const match = url.match(regex);
  if (!match) {
    return;
  }
  return match[0];
};

export const boldText = (text: string) => {
  const splitText = text.split('<strong>');
  let value = <>{splitText[0]}</>;
  for (let i = 1; i < splitText.length; i++) {
    const strongText = splitText[i].split('</strong>');
    value = (
      <>
        {value}
        <strong>{strongText[0]}</strong>
        {strongText[1]}
      </>
    );
  }
  return value;
};

export const underlineText = (text: string) => {
  const splitText = text.split('<u>');
  let value = <>{splitText[0]}</>;
  for (let i = 1; i < splitText.length; i++) {
    const strongText = splitText[i].split('</u>');
    value = (
      <>
        {value}
        <u>{strongText[0]}</u>
        {strongText[1]}
      </>
    );
  }
  return value;
};

export const boldAndUnderlineText = (text: string) => {
  const splitText = text.split('<strong>');
  let value = <>{underlineText(splitText[0])}</>;
  for (let i = 1; i < splitText.length; i++) {
    const strongText = splitText[i].split('</strong>');
    value = (
      <>
        {value}
        <strong>{underlineText(strongText[0])}</strong>
        {underlineText(strongText[1])}
      </>
    );
  }
  return value;
};

export const getPlayerName = (player: string) => {
  switch (player) {
    case 'player':
      return i18n.t('common:APPS.BAND');
    case 'choirplayer':
      return i18n.t('common:APPS.CHOIR');
    case 'boomwhackersplayer':
      return i18n.t('common:APPS.BOOMWHACKERS');
    case 'ukuleleplayer':
      return i18n.t('common:APPS.UKULELE');
    case 'guitarplayer':
      return i18n.t('common:APPS.GUITAR');
    case 'whitepianoplayer':
      return i18n.t('common:APPS.WHITEPIANO');
    case 'basstabsplayer':
      return i18n.t('common:APPS.BASSTABS');
    case 'pianonotationplayer':
      return i18n.t('common:APPS.PIANONOTATION');
    case 'blackjazzpianoplayer':
      return i18n.t('common:APPS.BLACKJAZZPIANO');
    case 'opentuningplayer':
      return i18n.t('common:APPS.OPENTUNING');
    case 'singplayer':
      return i18n.t('common:APPS.SING');
    default:
      return player;
  }
};
