import { ReactElement, useMemo, useState, useEffect, lazy, Suspense, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import Helmet from 'react-helmet';
import 'assets/css/main.css';
import 'i18n';
import { languages } from 'i18n';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import URLS from 'URLS';
import { UserProvider } from 'hooks/User';
import { AdminProvider } from 'apps/AdminPanel/hooks/useAdmin';
import { NotificationsProvider } from 'hooks/Notifications';
import { SnackbarProvider, useSnackbar } from 'hooks/Snackbar';
import { SchoolUserProvider, useAuth, useIsSchoolAuth } from 'hooks/Auth';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import firebase, { collection, COLLECTIONS } from 'fb';
import { subYears, endOfToday } from 'date-fns';
import { User } from 'types';
import CookieNotification from 'components/CookieNotification';
import { useUserState } from 'hooks/User';
import { OnboardingProvider, useOnboardingRedirect } from 'hooks/Onboarding';
import { SongsProvider } from 'SongCaching';
import { PlaylistsProvider } from 'apps/Players/hooks/usePlaylists';
import { PlayerDemoSongsProvider } from 'containers/PlayerDemo/PlayerDemoSongs';
import Landing from 'containers/Landing';
import Navigation from 'components/navigation/Navigation';
// Theme
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from 'theme';
// Project components
const ChallengeApp = lazy(() => import('apps/Challenge'));
const EventsApp = lazy(() => import('apps/Events'));
const MainPlayerApp = lazy(() => import('apps/Players/MainPlayer'));
const ChoirPlayerApp = lazy(() => import('apps/Players/ChoirPlayer'));
const BoomwhackersPlayerApp = lazy(() => import('apps/Players/BoomwhackersPlayer'));
const UkulelePlayerApp = lazy(() => import('apps/Players/UkulelePlayer'));
const GuitarPlayerApp = lazy(() => import('apps/Players/GuitarPlayer'));
const WhitePianoPlayerApp = lazy(() => import('apps/Players/WhitePianoPlayer'));
const PianoNotationPlayerApp = lazy(() => import('apps/Players/PianoNotationPlayer'));
const BlackJazzPianoPlayerApp = lazy(() => import('apps/Players/BlackJazzPianoPlayer'));
const BassTabsPlayerApp = lazy(() => import('apps/Players/BassTabsPlayer'));
const OpenTuningPlayerApp = lazy(() => import('apps/Players/OpenTuningPlayer'));
const SingPlayerApp = lazy(() => import('apps/Players/SingPlayer'));
const ComposerApp = lazy(() => import('apps/Composer'));
const AdminPanelApp = lazy(() => import('apps/AdminPanel'));
const AcademyApp = lazy(() => import('apps/Academy'));
const Hub = lazy(() => import('containers/Hub'));
const EdTech = lazy(() => import('containers/Hub/components/EdTech'));
const ResourcesApp = lazy(() => import('apps/Resources'));
const Auth = lazy(() => import('containers/Auth'));
const Chats = lazy(() => import('containers/Chats'));
const NotFound = lazy(() => import('containers/NotFound'));
const Account = lazy(() => import('containers/Account'));
const Organization = lazy(() => import('containers/Organization'));
const Request = lazy(() => import('containers/Request'));
const Privacy = lazy(() => import('containers/Privacy'));
const MailPreferences = lazy(() => import('containers/MailPreferences'));
const Pricing = lazy(() => import('containers/Pricing'));
const About = lazy(() => import('containers/About'));
const References = lazy(() => import('containers/References'));
const PlayerDemo = lazy(() => import('containers/PlayerDemo'));
const SchoolOnboarding = lazy(() => import('containers/SchoolOnboarding'));
const ShowInterest = lazy(() => import('containers/SchoolOnboarding/components/ShowInterest'));
const CreateLicense = lazy(() => import('containers/SchoolOnboarding/components/CreateLicense'));

export type ProvidersProps = {
  children: ReactNode;
};

export const Providers = ({ children }: ProvidersProps) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 5, // Don't refetch data before 5 min has passed
        refetchOnWindowFocus: false,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <UserProvider>
            <SchoolUserProvider>
              <AdminProvider>
                <SnackbarProvider>
                  <NotificationsProvider>
                    <OnboardingProvider>
                      <SongsProvider>
                        <PlaylistsProvider>
                          <PlayerDemoSongsProvider>
                            <CssBaseline />
                            {children}
                          </PlayerDemoSongsProvider>
                        </PlaylistsProvider>
                      </SongsProvider>
                    </OnboardingProvider>
                  </NotificationsProvider>
                </SnackbarProvider>
              </AdminProvider>
            </SchoolUserProvider>
          </UserProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export type AuthRouteProps = {
  path: string;
  element?: ReactElement;
  children?: ReactNode;
  ageRestrict?: boolean;
  schoolRestrict?: boolean;
  nonSchoolRestrict?: boolean;
  authRequired?: boolean;
  schoolRedirectUrl?: string;
  showFeideSnackbar?: boolean;
};

export const AuthRoute = ({
  ageRestrict,
  children,
  path,
  element,
  schoolRestrict = false,
  nonSchoolRestrict = false,
  authRequired = true,
  schoolRedirectUrl,
  showFeideSnackbar = true,
}: AuthRouteProps) => {
  const { t } = useTranslation(['common']);
  const location = useLocation();

  const { showSnackbar } = useSnackbar();
  const [auth, isAuthLoading] = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<firebase.firestore.DocumentSnapshot<User> | null>(null);

  useEffect(() => {
    let isSubscribed = true;
    if (!isAuthLoading) {
      if (auth) {
        collection<User>(COLLECTIONS.USERS)
          .doc(auth.uid)
          .get()
          .then((user) => {
            if (isSubscribed) {
              setUser(user.exists ? user : null);
              setIsLoading(false);
            }
          });
      } else {
        setIsLoading(false);
      }
    }
    return () => {
      isSubscribed = false;
    };
  }, [auth, isAuthLoading]);
  const over13 = useMemo(() => (user && user.data()!.birthday !== undefined ? user.data()!.birthday!.toDate() < subYears(endOfToday(), 13) : false), [user]);
  const haveParent = useMemo(() => (user ? Boolean(user.data()!.parents?.length) : false), [user]);
  const [isSchoolUser, isSchoolUserLoading] = useIsSchoolAuth();

  if (isLoading || isAuthLoading) {
    return <Navigation isLoading noFooter />;
  } else if ((!auth || !user) && authRequired) {
    return <Navigate state={{ referrer: location.pathname }} to={URLS.SIGN_IN} />;
  } else if ((isSchoolUser && schoolRestrict) || (!isSchoolUser && !user?.data()?.subscription?.schoolBypass && nonSchoolRestrict)) {
    if (showFeideSnackbar) {
      if (schoolRestrict) {
        setTimeout(() => showSnackbar(t('AUTH.NOACCESS.SCHOOLRESTRICTED'), { severity: 'info', length: 15000 }), 1000);
      } else if (nonSchoolRestrict) {
        setTimeout(() => showSnackbar(t('AUTH.NOACCESS.NONSCHOOLRESTRICTED'), { severity: 'info', length: 15000 }), 1000);
      }
    }

    return <Navigate to={schoolRedirectUrl || URLS.HUB} />;
  } else if (ageRestrict && !over13 && !haveParent && !isSchoolUser) {
    setTimeout(() => showSnackbar(t('AUTH.NOACCESS.NOPARENT'), { severity: 'info', length: 15000 }), 1000);
    return <Navigate to={URLS.HUB} />;
  } else {
    return (
      <Route element={element} path={path}>
        {children}
      </Route>
    );
  }
};

export type LanguageCheckProps = {
  children: ReactNode;
};

const LanguageCheck = ({ children }: LanguageCheckProps) => {
  const user = useUserState();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (user) {
      const userData = user.data();
      const currentLanguage = i18n.language;
      // if languageCode exist
      if (userData?.languageCode) {
        // if languageCode exist in valid languages
        if (languages.some((lang) => lang.code === userData.languageCode)) {
          // if local language is different from fb language
          if (currentLanguage !== userData.languageCode) {
            // change local language
            i18n.changeLanguage(userData?.languageCode);
          }
        }
      }
      // Change html lang on initial loading
      document.documentElement.setAttribute('lang', currentLanguage);
    }
  }, [user?.id]);
  return <div>{children}</div>;
};

const OnboardingRedirect = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { url, navigateNow, setNavigateNow, setAsDialog } = useOnboardingRedirect();

  useEffect(() => {
    if (navigateNow && url === location.pathname) {
      setAsDialog(false);
      setNavigateNow(false);
    } else if (url && navigateNow) {
      navigate(url);
      setNavigateNow(false);
    }
  }, [url, navigateNow]);

  return <>{children}</>;
};

const App = () => {
  return (
    <Providers>
      <Helmet>
        <link href='/manifest.json' rel='manifest' />
      </Helmet>
      <BrowserRouter>
        <Suspense fallback={null}>
          <LanguageCheck>
            <OnboardingRedirect>
              <Routes>
                <Route element={<Auth />} path={`${URLS.SIGN_IN}*`} />
                <Route element={<Landing />} path={URLS.LANDING} />
                <Route element={<Pricing />} path={URLS.PRICING} />
                <Route element={<About />} path={URLS.ABOUT} />
                <Route element={<References />} path={URLS.REFERENCES} />
                <Route element={<Hub />} path={URLS.HUB} />
                <Route element={<EdTech />} path={URLS.EDTECH} />
                <Route element={<Organization />} path={`${URLS.ORGANIZATION}:id/`} />
                <Route element={<Privacy />} path={`${URLS.PRIVACY}`} />
                <Route element={<SchoolOnboarding />} path={`${URLS.SCHOOLONBOARDING}:page/`} />
                <Route element={<ShowInterest />} path={`${URLS.SCHOOLONBOARDING_INTEREST}`} />
                <Route element={<CreateLicense />} path={`${URLS.SCHOOLONBOARDING_LICENSE}`} />
                <AuthRoute element={<Chats />} path={`${URLS.CHAT}*`} schoolRestrict={true} />
                <AuthRoute element={<Request />} path={`${URLS.REQUEST}:id/`} />
                <AuthRoute element={<Account />} path={URLS.ACCOUNT} />
                <AuthRoute element={<Account />} path={`${URLS.ACCOUNT}:params/`} />
                <AuthRoute element={<MailPreferences />} path={URLS.EMAIL_SUBSCRIPTIONS} />
                {process.env.REACT_APP_SHOW_CHALLENGE === 'TRUE' && (
                  <AuthRoute authRequired={false} element={<ChallengeApp />} path={`${URLS.CHALLENGE}*`} schoolRestrict={true} />
                )}
                {process.env.REACT_APP_SHOW_EVENTS === 'TRUE' && <Route element={<EventsApp />} path={`${URLS.EVENTS}*`} />}
                {process.env.REACT_APP_SHOW_PLAYER === 'TRUE' && <Route element={<MainPlayerApp />} path={`${URLS.PLAYER}*`} />}
                {process.env.REACT_APP_SHOW_CHOIRPLAYER === 'TRUE' && <Route element={<ChoirPlayerApp />} path={`${URLS.CHOIRPLAYER}*`} />}
                {process.env.REACT_APP_SHOW_BOOMWHACKERSPLAYER === 'TRUE' && (
                  <AuthRoute
                    element={<BoomwhackersPlayerApp />}
                    nonSchoolRestrict={true}
                    path={`${URLS.BOOMWHACKERSPLAYER}*`}
                    schoolRedirectUrl={URLS.EDTECH}
                  />
                )}
                {process.env.REACT_APP_SHOW_UKULELEPLAYER === 'TRUE' && <Route element={<UkulelePlayerApp />} path={`${URLS.UKULELEPLAYER}*`} />}
                {process.env.REACT_APP_SHOW_GUITARPLAYER === 'TRUE' && <Route element={<GuitarPlayerApp />} path={`${URLS.GUITARPLAYER}*`} />}
                {process.env.REACT_APP_SHOW_WHITEPIANOPLAYER === 'TRUE' && <Route element={<WhitePianoPlayerApp />} path={`${URLS.WHITEPIANOPLAYER}*`} />}
                {process.env.REACT_APP_SHOW_PIANONOTATIONPLAYER === 'TRUE' && (
                  <Route element={<PianoNotationPlayerApp />} path={`${URLS.PIANONOTATIONPLAYER}*`} />
                )}
                {process.env.REACT_APP_SHOW_BLACKJAZZPIANOPLAYER === 'TRUE' && (
                  <Route element={<BlackJazzPianoPlayerApp />} path={`${URLS.BLACKJAZZPIANOPLAYER}*`} />
                )}
                {process.env.REACT_APP_SHOW_BASSTABSPLAYER === 'TRUE' && <Route element={<BassTabsPlayerApp />} path={`${URLS.BASSTABSPLAYER}*`} />}
                {process.env.REACT_APP_SHOW_OPENTUNINGPLAYER === 'TRUE' && <Route element={<OpenTuningPlayerApp />} path={`${URLS.OPENTUNINGPLAYER}*`} />}
                {process.env.REACT_APP_SHOW_SINGPLAYER === 'TRUE' && <Route element={<SingPlayerApp />} path={`${URLS.SINGPLAYER}*`} />}
                {process.env.REACT_APP_SHOW_COMPOSER === 'TRUE' && <Route element={<ComposerApp />} path={`${URLS.COMPOSER}*`} />}s
                {process.env.REACT_APP_SHOW_ADMINPANEL === 'TRUE' && (
                  <AuthRoute authRequired={false} element={<AdminPanelApp />} path={`${URLS.ADMINPANEL}*`} schoolRestrict={true} />
                )}
                {process.env.REACT_APP_SHOW_ACADEMY === 'TRUE' && <Route element={<AcademyApp />} path={`${URLS.ACADEMY}*`} />}
                {process.env.REACT_APP_SHOW_RESOURCES === 'TRUE' && <Route element={<ResourcesApp />} path={`${URLS.RESOURCES}*`} />}
                <Route element={<PlayerDemo />} path={`${URLS.PLAYER_DEMO}:app`} />
                <Route element={<NotFound />} path='*' />
              </Routes>
            </OnboardingRedirect>
          </LanguageCheck>
        </Suspense>
      </BrowserRouter>
      <CookieNotification />
    </Providers>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
